import {PoScItemModel} from "@app/core/models/po-sc-item.model";

export class PickOrderItem extends PoScItemModel {
  _allowLocationCodesScan: boolean;
  _sortOrder: number; // actual sort order

  amountRequired: number;
  amountPicked: number;
  activeEmployeeId: number;
  showPurchaseTrackingModalOnAddToPicked: boolean;
  purchaseTrackingIds: string[];

  scanProcedure: 'any' | 'locationThenProduct' = 'any';
}
