<mat-dialog-content>
  <div class="app-dialog-header">
    <h2 class="title">API error</h2>
  </div>

  <pre class="error-message">{{httpErrorResponse.url}}<br/><br/>{{message}}<br/><br/>{{httpErrorResponse.error}}</pre>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button
          color="accent"
          (click)="onCloseClick()">
    <mat-icon>clear</mat-icon>
    Close
  </button>
</mat-dialog-actions>
