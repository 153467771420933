export type RegisteredDeviceScanType = 'laser' | 'camera';
export type RegisteredDeviceKeyboardType = 'custom' | 'built-in';

export class RegisteredDevice {
  id: number;
  username: string;
  devicename: string;
  apiToken: string;
  apiTokenExpire: Date;
  hasPhotoCamera: boolean;
  scanType: RegisteredDeviceScanType;
  keyboardType: RegisteredDeviceKeyboardType;
  scanInputTimeoutMs: number;
}
