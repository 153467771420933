<div class="wrapper wrapper--{{size}} wrapper--{{infoPaneStyle}} scan-type--{{scanType}}"
     [ngClass]="{'animate-in': animateIn}">

  <div class="inner">
    <mat-icon class="custom-icon">{{icon}}</mat-icon>
    <p [innerHTML]="text" [ngClass]="{'narrow': canClose}"></p>
  </div>

  <ng-content></ng-content>

  <div *ngIf="canClose" class="close">
    <button mat-icon-button
            (click)="onCloseClick()">
      <mat-icon>{{closeIcon}}</mat-icon>
    </button>
  </div>

</div>

