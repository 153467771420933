import {Directive, ElementRef, OnInit} from '@angular/core';
import {AuthService} from "@app/core/services/auth.service";

@Directive({
  selector: '[appFarSighted]'
})
export class FarSightedDirective implements OnInit {
  constructor(
    private authService: AuthService,
    private el: ElementRef
  ) {
  }

  ngOnInit(): void {
    if (this.authService.getEmployee()?.isFarSighted) {
      this.el.nativeElement.classList.add('is-far-sighted');
    }
  }
}
