<ng-container *ngIf="registeredDeviceIsCameraScanner">
  <div class="scanner-wrapper"
       [ngClass]="{'display-none': noCamerasAvailable || showPermissionDenied || showNotCompatible}">

    <div class="buttons">
      <button *ngIf="torchCompatible"
              mat-mini-fab
              color="primary"
              (click)="torch = !torch">
        <mat-icon *ngIf="!torch">flash_on</mat-icon>
        <mat-icon *ngIf="!torch">flash_off</mat-icon>
      </button>

      <button *ngIf="camerasFound?.length > 1"
              mat-mini-fab
              class="switch-camera"
              matTooltip="Wissel camera"
              (click)="onSwitchCamera()">
        <mat-icon>switch_camera</mat-icon>
      </button>
    </div>

    <div *ngIf="camerasFound" class="rendering-cam">
      <app-spinner [text]="'generic.camera.loadingImage' | translate" [stacked]="false"></app-spinner>
    </div>

    <zxing-scanner *ngIf="scannerEnabled" [formats]="appConfig.scanFormats"
                   [enable]="scannerEnabled"
                   [(device)]="desiredDevice"
                   [autostart]="true"
                   [tryHarder]="true"
                   [autofocusEnabled]="true"
                   [torch]="torch"
                   (torchCompatible)="onTorchCompatible($event)"
                   (camerasFound)="camerasFoundHandler($event)"
                   (camerasNotFound)="camerasNotFoundHandler()"
                   (scanSuccess)="scanSuccessHandler($event)"
                   (permissionResponse)="handlePermissionResponse($event)"
                   #scanner></zxing-scanner>
  </div>

  <div *ngIf="!showNotCompatible && !showPermissionDenied && !camerasFound && !noCamerasAvailable"
       class="camera-loading">
    <app-spinner [text]="'generic.camera.loadingCamera' | translate" [stacked]="false"></app-spinner>
  </div>


  <div *ngIf="showPermissionDenied" class="permission-denied">
    <b>{{'generic.camera.permissionForCameraDenied' | translate}}</b>
  </div>
</ng-container>
