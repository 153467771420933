import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {RegisteredDeviceGuard} from './core/guards/registered-device.guard';
import {NoRegisteredDeviceGuard} from "./core/guards/no-registered-device.guard";

import {EmployeeGuard} from './core/guards/employee.guard';
import {NoEmployeeGuard} from "./core/guards/no-employee.guard";

const routes: Routes = [
  {
    path: 'register-device',
    canActivate: [NoRegisteredDeviceGuard],
    loadChildren: () => import('./register-device/register-device.module').then(m => m.RegisterDeviceModule)
  },
  {
    path: 'set-employee',
    canActivate: [RegisteredDeviceGuard, NoEmployeeGuard],
    loadChildren: () => import('./set-employee/set-employee.module').then(m => m.SetEmployeeModule)
  },
  {
    path: 'dashboard',
    canActivate: [RegisteredDeviceGuard, EmployeeGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: '**',
    redirectTo: '/register-device'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
