<div class="wrapper">
  <form [formGroup]="form">
    <input matInput
           type="text"
           placeholder="Barcode"
           formControlName="scan">

    <button mat-icon-button
            color="primary"
            matTooltip="Fake scan"
            [disabled]="form.invalid"
            (click)="sendForm()">
      <mat-icon>qr_code_scanner</mat-icon>
    </button>
  </form>

  <button mat-icon-button
          color="accent"
          class="btn-reset"
          matTooltip="Reset testEmployee"
          (click)="onResetClick()">
    <mat-icon>undo</mat-icon>
  </button>

  <button mat-icon-button
          class="color--notification"
          matTooltip="Logs"
          (click)="openLogs()">
    <mat-icon>biotech</mat-icon>
  </button>

</div>
