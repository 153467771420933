<mat-dialog-content>
  <div class="app-dialog-header">
    <h2 class="title">Logs</h2>
  </div>
  <p [innerHTML]="logsService.log"></p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button
          color="warn"
          (click)="logsService.clearLog()">Maak leeg
  </button>

  <button mat-flat-button
          color="accent"
          (click)="dialogRef.close()">Sluiten
  </button>

</mat-dialog-actions>
