import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "./core/services/auth.service";
import {ScanService} from "./core/services/scan.service";
import {environment} from "@env/environment";
import {appConfig, RegisteredDevice} from "@app/core";
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {SortItemsByService} from "@app/core/services/sort-items-by.service";
import {NetworkDevicesService} from "@app/core/services/network-devices.service";
import {NgSelectConfig} from '@ng-select/ng-select';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private routerEvents$: Subscription;
  private registeredDevice$: Subscription;
  private registeredDevice: RegisteredDevice;

  env = environment;
  keyUpReading = false;
  keyUpScannerInput;
  textInputReading = false;
  textInputScannerInput;

  @HostListener('document:textInput', ['$event'])
  handleTextInput(e) {
    if (this.registeredDevice?.keyboardType === 'custom') {
      e.preventDefault();
    }
    this.handleTextInputEvent(e);
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyUp(e) {
    this.handleKeyUpEvent(e);
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private ngSelectConfig: NgSelectConfig,
    private scannerInputService: ScanService,
    // Bug; networkDevicesService required to be set here; otherwise the constructor will not be called on boot
    private networkDevicesService: NetworkDevicesService,
    public sortItemsByService: SortItemsByService,
  ) {
    this.ngSelectConfig.appendTo = 'body';
  }

  ngOnInit(): void {
    this.registeredDevice$ = this.authService.registeredDevice$.subscribe(registeredDevice => {
      this.registeredDevice = registeredDevice;
    })
    this.subscribeToRouteChanges();
    this.setRegisteredDeviceUsingLocalStorage();
    this.setEmployeeUsingLocalStorage();
    this.initScanner();
  }

  private subscribeToRouteChanges() {
    this.routerEvents$ = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        document.getElementsByTagName('mat-sidenav-content')[0]?.scrollTo(0, 0);
      }
    });
  }

  private setRegisteredDeviceUsingLocalStorage() {
    const storage = localStorage.getItem(appConfig.localStorageKeys.registeredDevice);
    if (!storage) {
      return null;
    }
    const registeredDevice: RegisteredDevice = JSON.parse(storage) as RegisteredDevice;
    if (!registeredDevice.keyboardType) registeredDevice.keyboardType = 'custom';
    this.authService.setRegisteredDevice(registeredDevice);
  }

  private setEmployeeUsingLocalStorage() {
    const storage = localStorage.getItem(appConfig.localStorageKeys.employee);
    if (!storage) return null;
    const employee = JSON.parse(storage);
    this.authService.setEmployee(employee);
  }

  private initScanner() {
    this.keyUpScannerInput = '';
    this.keyUpReading = false;
    this.textInputScannerInput = '';
    this.textInputReading = false;
  }

  private handleTextInputEvent(e) {
    const textInputString = `${e.data}`;
    if (textInputString === '') return;
    if (!/^[A-Za-z0-9]*$/.test(textInputString) && textInputString !== '-') return;
    this.textInputScannerInput += textInputString;
    if (this.textInputReading) return;
    this.textInputReading = true;
    setTimeout(() => {
      if (this.textInputScannerInput.length > appConfig.scannerPreFixPostFix.length) this.handleTextInputScannerInputLongerThanPrePostFix();
      this.textInputScannerInput = '';
      this.textInputReading = false;
    }, this.authService.getRegisteredDevice()?.scanInputTimeoutMs || 75);
  }

  private handleTextInputScannerInputLongerThanPrePostFix() {
    this.scannerInputService.handleLaserScanInput(this.textInputScannerInput);
    this.tryToClearTextInputResultFromFocussedInputElement();
  }

  private tryToClearTextInputResultFromFocussedInputElement() {
    if (!this.registeredDevice) return;
    if (this.registeredDevice.keyboardType === 'custom') return;
    const focussedEl: HTMLInputElement = document.activeElement as HTMLInputElement;
    const value = focussedEl?.value;
    if (!value || value === '') return;
    if (value === this.textInputScannerInput) {
      focussedEl.value = '';
      focussedEl.blur();
    } else {
      let split = value.split(this.textInputScannerInput);
      if (split.length < 2) split = value.split(this.textInputScannerInput.toLowerCase());
      if (split.length < 2) return;
      focussedEl.value = split.join('');
      focussedEl.blur();
    }
  }

  private handleKeyUpEvent(e) {
    if (!AppComponent.getKeyCodeIsValidForScan(e)) return;
    this.keyUpScannerInput += (e.keyCode === 189) ? '-' : String.fromCharCode(e.keyCode);
    if (this.keyUpReading) return;
    this.keyUpReading = true;
    setTimeout(() => {
      if (this.keyUpScannerInput.length > appConfig.scannerPreFixPostFix.length) this.scannerInputService.handleLaserScanInput(this.keyUpScannerInput);
      this.keyUpScannerInput = '';
      this.keyUpReading = false;
    }, this.authService.getRegisteredDevice()?.scanInputTimeoutMs || 75);
  }

  private static getKeyCodeIsValidForScan(e): boolean {
    if (e.keyCode >= 48 && e.keyCode <= 57) return true; // numbers 0 - 9
    if (e.keyCode >= 65 && e.keyCode <= 90) return true; // a - z
    if (e.keyCode === 189) return true; // hypen / -
    return false;
  }

  ngOnDestroy(): void {
    this.routerEvents$?.unsubscribe();
    this.registeredDevice$?.unsubscribe();
  }
}
