import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AuthService} from "@app/core/services/auth.service";
import {Subscription} from "rxjs";
import {RegisteredDeviceScanType} from '@app/core/index';

export type InfoPaneStyle = 'calm' | 'notification' | 'warning' | 'positive';

@Component({
  selector: 'app-info-pane',
  templateUrl: './info-pane.component.html',
  styleUrls: ['./info-pane.component.scss']
})
export class InfoPaneComponent implements OnInit, OnDestroy {
  @Input() animateIn = false;
  @Input() text: string = '';
  @Input() icon: string = 'info';
  @Input() size: 'small' | 'med' | 'large' = 'med';
  @Input() infoPaneStyle: InfoPaneStyle = 'calm';
  @Input() canClose = false;
  @Input() closeIcon = 'close';
  @Input() forceScanType: string;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  private registeredDevice$: Subscription;
  scanType: RegisteredDeviceScanType;

  constructor(
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    if (this.forceScanType) {
      this.scanType = 'laser';
    } else {
      this.registeredDevice$ = this.authService.registeredDevice$.subscribe(registeredDevice => {
        this.scanType = registeredDevice?.scanType;
      })
    }
  }

  onCloseClick() {
    this.onClose?.emit();
  }

  ngOnDestroy(): void {
    this.registeredDevice$?.unsubscribe();
  }

}
