import {appConfig} from "@app/core/app-config";

declare const require: any;

export const environment = {
  production: false,
  apiBaseUrl: appConfig.api.test,
  apiPrefix: 'webapp',
  useProxy: false,
  version: require('../../package.json').version,
  ws: appConfig.ws.test,
  features: {
    employeePause: false,
    packaging: true,
    scanFotoForText: true,
  }
};
