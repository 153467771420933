import {MatDialogConfig} from "@angular/material/dialog";
import {HoldReason} from "@app/core/models/hold-reason.model";
import {PrintDocumentType} from "@app/core/models";
import {BarcodeFormat} from "@zxing/library";

const fullScreenDialogConfig = Object.assign(new MatDialogConfig(), {
  panelClass: ['app-dialog', 'full-screen-dialog'],
  maxWidth: null,
  maxHeight: null
});

const baseUrl = 'app-begra.nl'; // 'failsafe.app-begra.nl';
const baseApiUrl = `https://${baseUrl}:16102/wms`;
const baseWSUrl = `wss://${baseUrl}:16103/wms`;

export const appConfig = {
  // URL's
  api: {
    dev: baseApiUrl,
    test: baseApiUrl,
    live: baseApiUrl,
  },
  ws: {
    dev: baseWSUrl,
    test: baseWSUrl,
    live: baseWSUrl,
  },
  productImages: 'https://images.intranet-begra.nl/',
  articleCodePrefixes: ['IO', 'MP', 'QS', 'BM', 'SS'],

  //PickOrders
  allUnorderedCategoriesGroupedId: 'all-unordered-categories-grouped',

  // LocalStorage Keys
  localStorageKeys: {
    // System
    registeredDevice: 'registeredDevice',
    employee: 'employee',
    showOnlyIncompleteCategories: 'showOnlyIncompleteCategories',
    groupUnorderedCategories: 'groupUnorderedCategories',
    showOnlyIncompleteProductItems: 'showOnlyIncompleteProductItems',
    showOnlyIncompletePackages: 'showOnlyIncompletePackages',
    sortItemsBy: 'sortItemsBy',
    croppingRectangle: 'croppingRectangle',

    // Inbounds
    inboundsListPickUpByNameExpandedIds: 'inboundsListPickUpByNameExpandedIds',
    inboundsListGroupByType: 'inboundsListGroupByType',
    inboundSearchQuery: 'inboundSearchQuery',
    showOnlyIncompleteInboundItems: 'showOnlyIncompleteInboundItems',

    // PickOrders
    activePickOrderId: 'activePickOrderId',
    activePickOrderCategoryId: 'activePickOrderCategoryId',
    activePickOrderItemId: 'activePickOrderItemId',
    scannedPickOrderItemId: 'scannedPickOrderItemId',
    pickOrderComments: (pickOrderId: string, employeeId: number): string => {
      return `pickOrderComments_${pickOrderId}_${employeeId}`;
    },
    skippedPickOrderItemsInPickOrderCategory: (pickOrderId: string, pickOrderCategoryId: string): string => {
      return `skippedPickOrderItemsInPickOrderCategory_${pickOrderId}_${pickOrderCategoryId}`;
    },

    // Packaging
    returnToPickOrderIdAfterCompletingPackage: 'returnToPickOrderIdAfterCompletingPackage',

    // Outbounds
    lastVisitedOutboundOverviewPage: 'lastVisitedOutboundOverviewPage',
    outboundsListPickUpByNameExpandedIds: 'outboundsListPickUpByNameExpandedIds',
    outboundsListGroupByType: 'outboundsListGroupByType',
    outboundSearchQuery: 'outboundSearchQuery',

    // StockChecks
    activeStockCheckId: 'activeStockCheckId',
    activeStockCheckItemId: 'activeStockCheckItemId',
    scannedStockCheckItemId: 'scannedStockCheckItemId',
    skippedStockCheckItemsInStockCheck: (stockCheckId: string): string => {
      return `skippedStockCheckItemsInStockCheck_${stockCheckId}`;
    },

    // Manage Products
    manageProductsSearchQuery: 'manageProductsSearchQuery',
    manageProductsRecentIds: 'manageProductsRecentIds',
    manageProductsStockDialogOpenPanels: 'manageProductsStockDialogOpenPanels',

    // StockCorrection reasons
    lastSelectedStockCorrectionReasonId: 'lastSelectedStockCorrectionReasonId',
    lastSelectedEntryType: 'lastSelectedEntryType',
  },

  // Date Formats
  dateTimeFormat: 'dd-MM-yy HH:mm:ss',
  dateFormat: 'dd-MM-yy',

  // Keyboard
  keyboardOptions: [
    'custom',
    'built-in'
  ],
  // Scanner
  scannerOptions: [
    'laser',
    'camera'
  ],
  scanFormats: [BarcodeFormat.QR_CODE, BarcodeFormat.AZTEC, BarcodeFormat.CODE_128] as BarcodeFormat[],
  networkDeviceScanPrefix: 'PC',
  scannerPreFixPostFix: 'QQQ',

  // HoldReasons
  holdReasons: [
    {
      id: 'notAvailableInCurrentWarehouse',
      isReverseAble: true,
      label: 'Items niet in opslag in huidige magazijn',
    },
    {
      id: 'itemsNotInStorage',
      isReverseAble: false,
      label: 'Items niet meer beschikbaar in schap',
    }
  ] as HoldReason[],

  // Print
  printDocumentTypes: [
    {
      id: 'pickOrder',
      label: 'Pickorder',
    },
    {
      id: 'productBarcode',
      label: 'Product barcode',
    },
    {
      id: 'inboundOrder',
      label: 'Inbound order',
    },
  ] as PrintDocumentType[],

  fullScreenDialogConfig: fullScreenDialogConfig as MatDialogConfig,
}
