// Angular
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// Modules
import {CoreModule} from '@app/core';
import {SharedModule} from '@app/shared';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ManualScanInputComponent} from './manual-scan-input.component';
import {DecimalPipe} from "@angular/common";
import {VersionCheckerComponent} from './version-checker.component';
import {AngularPageVisibilityModule} from "angular-page-visibility";

@NgModule({
  declarations: [
    AppComponent,
    ManualScanInputComponent,
    VersionCheckerComponent
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularPageVisibilityModule,

    // Modules
    CoreModule,
    SharedModule,

    // App
    AppRoutingModule,
  ],
  providers: [
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
