import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from "../../../environments/environment";
import {AuthService} from "../services/auth.service";
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ApiErrorDialogComponent} from "../../shared/components/dialogs/api-error-dialog/api-error-dialog.component";
import {Router} from "@angular/router";
import {appConfig} from "@app/core/app-config";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private dialogRef: MatDialogRef<ApiErrorDialogComponent, any>;
  private transFormedHeaders = {};

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const transformedRequest = this.getTransformedRequest(request);
    return next.handle(transformedRequest).pipe(
      tap(
        (response) => {
          if (response instanceof HttpResponse) {
            this.handleHttpResponse(response);
          }
        },
        (response: HttpErrorResponse) => {
          this.handleErrorResponse(response);
        }
      )
    );
  }

  private getTransformedRequest(request: HttpRequest<any>) {
    this.transFormedHeaders = {};
    this.transFormedHeaders['Accept'] = 'application/json';

    if (!this.isTranslationOrVersionCheckerUrl(request.url)) {
      const registeredDevice = this.authService.getRegisteredDevice();
      if (registeredDevice) this.transFormedHeaders['apitoken'] = registeredDevice.apiToken;

      const employee = this.authService.getEmployee();
      if (employee) this.transFormedHeaders['employeeId'] = `${employee.id}`;
      if (employee) this.transFormedHeaders['wareHouse'] = `${employee.wareHouse}`;
    }

    return request.clone({
      url: this.getTransformedUrl(request.url),
      setHeaders: this.transFormedHeaders
    });
  }

  private getTransformedUrl(url: string): string {
    if (this.isTranslationOrVersionCheckerUrl(url)) return url;
    if (environment.useProxy) {
      return `proxyApi/${environment.apiPrefix}/${url}/`;
    } else {
      return `${environment.apiBaseUrl}/${environment.apiPrefix}/${url}/`;
    }
  }

  private isTranslationOrVersionCheckerUrl(url: string) {
    return url.split('/assets/i18n/').length > 1 || url.split('/assets/version.json').length > 1;
  }

  private handleHttpResponse(response: HttpResponse<any>) {
    const apiTokenExpire = response.headers.get('apitoken-expires');
    if (!apiTokenExpire) return null;
    this.authService.setApiTokenExpire(apiTokenExpire);
    this.authService.apiOffline = false;
  }

  private handleErrorResponse(response: HttpErrorResponse): void {
    if (response.url) {
      if (response.url.split('/assets/version.json').length > 1 || response.url.split('update_api_token_expire').length > 1) return;
    }

    if (response.status === 504) {
      this.authService.apiOffline = true;
      return;
    }

    if (response.status === 403) {
      this.authService.unSetEmployee();
      this.authService.unSetRegisteredDevice();
      this.router.navigateByUrl('/register-device');
      return;
    }

    if (response.status === 401) return;

    this.showApiErrorDialog(response);
  }

  private showApiErrorDialog(response: HttpErrorResponse) {
    this.dialogRef?.close();
    this.dialogRef = this.dialog.open(ApiErrorDialogComponent, {
      data: response
    });
  }
}
