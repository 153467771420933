import {Component} from '@angular/core';
import {take} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-back-end-offline',
  templateUrl: './back-end-offline.component.html',
  styleUrls: ['./back-end-offline.component.scss']
})
export class BackEndOfflineComponent {
  isPending = false;

  constructor(
    private httpClient: HttpClient
  ) {
  }

  onReconnect() {
    if (this.isPending) return null;
    this.isPending = true;
    this.httpClient.get('update_api_token_expire').pipe(take(1)).subscribe(() => {
      this.isPending = false;
    }, () => {
      this.isPending = false;
    });
  }

}
