import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {appConfig} from "@app/core";
import {AuthService} from "@app/core/services/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class ScanService {
  scan$: BehaviorSubject<string>;
  networkDeviceScan$: BehaviorSubject<string>;

  constructor(
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
  ) {
    this.scan$ = new BehaviorSubject<string>(null);
    this.networkDeviceScan$ = new BehaviorSubject<string>(null);
  }

  handleLaserScanInput(scanInput: string) {
    this.handleRawScanLogging(scanInput);
    if (!scanInput) return null;
    let splitScan = scanInput.split(appConfig.scannerPreFixPostFix);
    if (splitScan.length !== 3) splitScan = scanInput.split(appConfig.scannerPreFixPostFix.toLowerCase());
    if (splitScan.length !== 3) return;
    this.handleProcessedScanLogging(splitScan);
    this.handleScan(splitScan[1]);
  }

  private handleRawScanLogging(scanInput: string) {
    if (this.authService.getEmployee()?.logRawScanInfo) {
      this.matSnackBar.open(`Raw scan: '${scanInput}'`, 'Sluiten', {
        duration: 4000, verticalPosition: 'top'
      });
    }
  }

  private handleProcessedScanLogging(splitScan: string[]) {
    if (this.authService.getEmployee()?.logRawScanInfo) {
      this.matSnackBar.open(`Processed scan: '${splitScan[1]}'`, 'Sluiten', {
        duration: 4000, verticalPosition: 'bottom',
      });
    }
  }

  handleCameraScanInput(scanInput: string) {
    if (!scanInput) return null;
    // Regex; all numbers, all letters and '-'
    const cleanedUpScan = scanInput.match(/([A-Za-z0-9\-]+)/g).join('');
    this.handleScan(cleanedUpScan);
  }

  handleManualInput(scanInput: string) {
    if (!scanInput) {
      this.scan$.next(null);
      this.networkDeviceScan$.next(null);
    } else {
      this.handleScan(scanInput);
    }
  }

  private handleScan(scan: string) {
    const networkDeviceScanSplit = scan.split(appConfig.networkDeviceScanPrefix);
    if (networkDeviceScanSplit.length > 1 && networkDeviceScanSplit[0] === '') {
      this.networkDeviceScan$.next(networkDeviceScanSplit[1]);
    } else {
      this.scan$.next(scan);
    }
  }
}
