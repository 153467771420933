import {environment} from "@env/environment";

// Angular
import {NgModule} from '@angular/core';
import {CommonModule,} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from "@angular/router";

// Pipes and Components
import {components} from './components';

//MAT
import {MatRippleModule} from '@angular/material/core';
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatMenuModule} from "@angular/material/menu";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTabsModule} from "@angular/material/tabs";
import {MatButtonModule} from "@angular/material/button";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatBadgeModule} from "@angular/material/badge";
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";

// Other 3rd Party
import {TruncateModule} from '@yellowspot/ng-truncate';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {FarSightedDirective} from './directives/far-sighted.directive';
import {AngularSignaturePadModule} from '@almothafar/angular-signature-pad';
import {ImagePathPipe} from "@app/shared/pipes/image-path.pipe";
import {PinchZoomModule} from '@olafvv/ngx-pinch-zoom';
import { DialogAlignTopForCameraScanTypePipe } from './pipes/dialog-align-top-for-camera-scan-type.pipe';
import {MatRadioModule} from "@angular/material/radio";
import {ImageCropperModule} from "ngx-image-cropper";
import {AutofocusDirective} from '@app/shared/directives/auto-focus.directive';
import {ButtonIsPendingDirective} from '@app/shared/directives/button-is-pending.directive';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // MAT
    MatRippleModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatSidenavModule,
    MatListModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatCheckboxModule,
    DragDropModule,
    MatBadgeModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatRadioModule,

    // Other 3rd Party
    TruncateModule,
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ZXingScannerModule,
    AngularSignaturePadModule,
    PinchZoomModule,
    ImageCropperModule,
  ],
  declarations: [
    ...components,
    FarSightedDirective,
    AutofocusDirective,
    ButtonIsPendingDirective,
    ImagePathPipe,
    DialogAlignTopForCameraScanTypePipe
  ],
  exports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // MAT
    MatRippleModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatSidenavModule,
    MatListModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatCheckboxModule,
    DragDropModule,
    MatBadgeModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    MatRadioModule,

    // Other 3rd Party
    TruncateModule,
    NgSelectModule,
    TranslateModule,
    AngularSignaturePadModule,
    PinchZoomModule,
    ImageCropperModule,

    ...components,
    FarSightedDirective,
    AutofocusDirective,
    ButtonIsPendingDirective,
    ImagePathPipe,
    DialogAlignTopForCameraScanTypePipe
  ],
  providers: [
    DialogAlignTopForCameraScanTypePipe
  ]
})
export class SharedModule {
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(
    httpClient,
    '/assets/i18n/',
    `.json?cacheBuster=${environment.version}`
  );
}
