import {StockCheckItem} from "@app/core";

export class StockCheck {
  id: string;
  name: string;
  type: 'ordered' | 'unordered';
  activeEmployeeIds: number[];
  stockCheckItems: StockCheckItem[];
  completedItemsCount: number;
  isCompleted: boolean;
  isPickToLight: boolean;
  holdReasonId: string;
  holdComment: string;
}
