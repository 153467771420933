import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appButtonIsPending]'
})
export class ButtonIsPendingDirective implements OnChanges {
  @Input() appButtonIsPending = false;

  constructor(
    private el: ElementRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes?.appButtonIsPending?.currentValue) {
      this.el.nativeElement.classList.add('app-button-is-pending');
    } else {
      this.el.nativeElement.classList.remove('app-button-is-pending');
    }
  }
}
