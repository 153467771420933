import {PickOrderCategory} from "@app/core/models/pick-order-category.model";
import {Comment} from "@app/core/models/comment.model";
import {Package} from "@app/core/models/package.model";

export class PickOrder {
  _hasUnreadComments: boolean;
  _currentEmployeeIsOwner: boolean;
  _currentEmployeeIsRecipient: boolean;

  id: string;
  customer: string;
  commissionedBy: string;
  serviceLevel: string;
  pickOrderCategories: PickOrderCategory[];
  holdReasonId: string;
  holdComment: string;
  isCompleted: boolean;
  comments: Comment[];
  ownerEmployeeIds: number[];
  sharedWithEmployeeIds: number[];
  packages: Package[];
  totalWeight: number;
  totalVolume: number;
  plannedPickTimeMinutes: number;
  prio: string;
  canForwardFromPickOrderToPackageModule: boolean;
  allowedPackagingTypeIds: number[];
}
