export class PoScItemModel {
  _parentCategoryId: string;

  id: string;
  sortOrder: number; // default sort order passed from server
  title: string;
  description: {
    primary: string;
    secondary: string;
    supplierItemId: string;
    unit: string;
    weightPerItem: number;
    volumePerItem: number;
  }
  locationCodes: string[];
  amountRequired: number;
  amountPicked: number;
  activeEmployeeId: number;
  totalWeight: number;
  totalVolume: number;
  showPurchaseTrackingModalOnAddToPicked: boolean;
  purchaseTrackingIds: string[];
}
