import {PoScItemModel} from "@app/core/models/po-sc-item.model";

export class StockCheckItem extends PoScItemModel {
  _parentCategoryId: string;

  amountExpected: number;
  tolerancePercentage: number;
  toleranceAmount: number;
  stockAmount: number;
}
