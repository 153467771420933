import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { appConfig } from '@app/core/app-config';
import {AuthService} from "@app/core/services/auth.service";
import {ScanService} from "@app/core/services/scan.service";
import {ZXingScannerComponent} from "@zxing/ngx-scanner";

@Component({
  selector: 'app-camera-scanner',
  templateUrl: './camera-scanner.component.html',
  styleUrls: ['./camera-scanner.component.scss']
})
export class CameraScannerComponent implements OnInit, AfterViewInit, OnDestroy {
  appConfig = appConfig;
  registeredDeviceIsCameraScanner: boolean;
  lastScannedCodeDate: number;
  lastScannedCode: string;
  showPermissionDenied = false;
  showNotCompatible = false;
  torchCompatible = false;
  noCamerasAvailable = false;
  camerasFound: MediaDeviceInfo[];
  scannerEnabled = true;
  torch = false;
  desiredDevice = null;
  selectedDevice = null;

  @ViewChild('scanner', {static: false}) scanner: ZXingScannerComponent;

  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private scanService: ScanService,
  ) {
  }

  ngOnInit(): void {
    this.setRegisteredDeviceIsCameraScanner();
  }

  private setRegisteredDeviceIsCameraScanner() {
    this.registeredDeviceIsCameraScanner = this.authService.getRegisteredDevice().scanType === 'camera';
  }

  ngAfterViewInit() {
    this.scannerEnabled = true;
  }

  scanSuccessHandler(code: string) {
    // ignore duplicates for an interval of 1.5 seconds
    const now = new Date().getTime();
    if (code === this.lastScannedCode && (now < this.lastScannedCodeDate + 1500)) {
      return;
    }
    this.lastScannedCode = code;
    this.lastScannedCodeDate = now;
    this.beep();
    this.scanService.handleCameraScanInput(this.lastScannedCode);
    this.changeDetectorRef.detectChanges();
  }

  handlePermissionResponse($event) {
    if ($event === false) {
      this.showPermissionDenied = true;
      return;
    }
    if ($event === null) {
      this.showNotCompatible = true;
      return;
    }
  }

  onTorchCompatible($event) {
    if ($event === false) {
      this.torchCompatible = false;
      return;
    }
    if ($event === true) {
      this.torchCompatible = true;
      return;
    }
  }

  camerasFoundHandler($event) {
    if (!$event || $event.length === 0) return;
    this.camerasFound = $event;
  }

  camerasNotFoundHandler() {
    this.noCamerasAvailable = true;
  }

  beep() {
    const audio = new Audio();
    audio.src = 'assets/audio/beep.wav';
    audio.load();
    audio.play();
  }

  onSwitchCamera() {
    if (!this.desiredDevice && !this.selectedDevice) return;
    let currDeviceIndex: number;

    if (this.desiredDevice) {
      currDeviceIndex = this.camerasFound.findIndex(camera => {
        return this.desiredDevice.deviceId === camera.deviceId;
      });
    }

    if (this.selectedDevice) {
      currDeviceIndex = this.camerasFound.findIndex(camera => {
        return this.selectedDevice.deviceId === camera.deviceId;
      });
    }

    const nextDeviceIndex = (currDeviceIndex === (this.camerasFound.length - 1)) ? 0 : currDeviceIndex + 1;
    this.selectedDevice = Object.assign({}, this.camerasFound[nextDeviceIndex]);
    this.desiredDevice = this.selectedDevice;
  }

  ngOnDestroy(): void {
    this.scannerEnabled = false;
    this.changeDetectorRef.detectChanges();
    if (this.scanner) {
      this.scanner.reset();
    }
  }
}
