import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

import {take} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-api-expire-dialog',
  templateUrl: './api-expire-dialog.component.html',
  styleUrls: ['./api-expire-dialog.component.scss'],
})
export class ApiExpireDialogComponent {
  isPending = false;

  constructor(
    private dialogRef: MatDialogRef<ApiExpireDialogComponent>,
    private httpClient: HttpClient,
  ) {
  }

  onRefreshClick() {
    if (this.isPending) return null;
    this.isPending = true;
    this.httpClient.get('update_api_token_expire').pipe(take(1)).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
