import { Component, OnInit } from '@angular/core';
import {LogsService} from '@app/dashboard/services/logs.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-logs-dialog',
  templateUrl: './logs-dialog.component.html',
  styleUrls: ['./logs-dialog.component.scss']
})
export class LogsDialogComponent implements OnInit {

  constructor(
    public logsService: LogsService,
    public dialogRef: MatDialogRef<LogsDialogComponent>
  ) { }

  ngOnInit(): void {
  }

}
