// noinspection TypeScriptPreferShortImport
import {BreadCrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {LoadingComponent} from './loading/loading.component';
import {InfoPaneComponent} from './info-pane/info-pane.component';
import {ApiExpireComponent} from './api-expire/api-expire.component';
import {CameraScannerComponent} from './camera-scanner/camera-scanner.component';
import {BackEndOfflineComponent} from './back-end-offline/back-end-offline.component';
import {SpinnerComponent} from "./spinner/spinner.component";

// Dialogs
import {ApiErrorDialogComponent} from './dialogs/api-error-dialog/api-error-dialog.component';
import {ApiExpireDialogComponent} from './dialogs/api-expire-dialog/api-expire-dialog.component';

export const components = [
  BreadCrumbsComponent,
  LoadingComponent,
  InfoPaneComponent,
  ApiExpireComponent,
  CameraScannerComponent,
  BackEndOfflineComponent,
  SpinnerComponent,

  // Dialogs
  ApiErrorDialogComponent,
  ApiExpireDialogComponent,
];

export {BreadCrumbsComponent} from './breadcrumbs/breadcrumbs.component';
export {LoadingComponent} from './loading/loading.component';
export {InfoPaneComponent} from './info-pane/info-pane.component';
export {ApiExpireComponent} from './api-expire/api-expire.component';
export {CameraScannerComponent} from './camera-scanner/camera-scanner.component';
export {BackEndOfflineComponent} from './back-end-offline/back-end-offline.component';
export {SpinnerComponent} from "./spinner/spinner.component";

// Dialogs
export {ApiErrorDialogComponent} from './dialogs/api-error-dialog/api-error-dialog.component';
export {ApiExpireDialogComponent} from './dialogs/api-expire-dialog/api-expire-dialog.component';
