import {Package} from "@app/core/models/package.model";
import {Photo} from "@app/core/models/photo.model";

export class OutboundOrder {
  id: string;
  pickUpByName: string;
  activeEmployeeId: number;

  packages: Package[];
  totalPackagesCount: number;
  completedPackagesCount: number;

  paymentStep: 0 | 1 | 2;
  isPaid: boolean;

  signatureStep: 0 | 1 | 2;
  signature: string;
  signedByName: string;

  photoStep: 0 | 1 | 2;
  photos: Photo[];

  amountOfDaysInExpedition: number;

  scanDeliveryLocationToCompletePackageStep: 0 | 1 | 2 | 3;
}

export enum OutBoundOrderStepOptionsEnums {
  Hidden,
  Optional,
  Required,
  RequiredEnterManual
}
