import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {appConfig} from "@app/core/index";

export interface ImagePathOptions {
  folder?: string,
  asCss?: boolean,
  addRandomisedGet?: 'week' | 'day' | 'hour' | 'minute' | 'second' | 'millisecond',
}

@Pipe({
  name: 'imagePath'
})
export class ImagePathPipe implements PipeTransform {
  constructor(
    private domSanitizer: DomSanitizer,
  ) {
  }

  transform(fileName, options: ImagePathOptions): string {
    if (!fileName) return null;

    let url;
    if (options.folder) {
      url = `${appConfig.productImages}${options.folder}/${fileName}`;
    } else {
      url = fileName;
    }

    let cache = this.getCache(options.addRandomisedGet);

    url += `?cacheDisable=${cache}`

    if (options.asCss) {
      return this.domSanitizer.bypassSecurityTrustUrl(`url('${url}')`) as string;
    } else {
      return this.domSanitizer.bypassSecurityTrustUrl(url) as string;
    }
  }

  private getCache(addRandomisedGet): number {
    let cache = new Date().getTime();

    if (addRandomisedGet === 'millisecond' || !addRandomisedGet) {
      return cache;
    }

    cache = this.getSecondsFromMilliseconds(cache);
    if (addRandomisedGet === 'second') return cache;

    cache = this.getMinutesFromSeconds(cache);
    if (addRandomisedGet === 'minute') return cache

    cache = this.getHoursFromMinutes(cache);
    if (addRandomisedGet === 'hour') return cache

    cache = this.getDaysFromHours(cache);
    if (addRandomisedGet === 'day') return cache

    cache = this.getWeeksFromDays(cache);
    return cache
  }

  private getSecondsFromMilliseconds(milliseconds: number) {
    return Math.floor(milliseconds / 1000);
  }

  private getMinutesFromSeconds(seconds: number) {
    return Math.floor(seconds / 60);
  }

  private getHoursFromMinutes(minutes: number) {
    return Math.floor(minutes / 60);
  }

  private getDaysFromHours(hours: number) {
    return Math.floor(hours / 24);
  }

  private getWeeksFromDays(days: number) {
    return Math.floor(days / 7);
  }
}
