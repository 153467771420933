<mat-dialog-content>
  <div class="app-dialog-header">
    <h2 class="title">Your API-session is about to expire</h2>
  </div>

  <p>Please refresh your session if you want to continue. When no action is taken, this device will be unregisterd after
    1 minute.</p>
</mat-dialog-content>

<mat-dialog-actions>

  <button mat-raised-button
          color="accent"
          [appButtonIsPending]="isPending"
          (click)="onRefreshClick()">
    <mat-icon>refresh</mat-icon>
    Refresh session
  </button>
</mat-dialog-actions>
