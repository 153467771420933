import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, Optional, SkipSelf} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ApiInterceptor} from './interceptors/api-interceptor.service';
import {throwIfAlreadyLoaded} from './guards/module-import.guard';
import localeNl from '@angular/common/locales/nl';

import {TranslatePipe} from '@ngx-translate/core';

// Guards
import {RegisteredDeviceGuard} from "./guards/registered-device.guard";
import {DatePipe, registerLocaleData} from "@angular/common";
import {SortPickOrderItemsInPickOrderCategoryPipe} from './pipes/sort-pick-order-items-in-pick-order-category.pipe';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MAT_PAGINATOR_DEFAULT_OPTIONS} from '@angular/material/paginator';


export const appDialogConfig = {
  maxWidth: '95vw',
  minWidth: '320px',
  maxHeight: null,
  panelClass: 'app-dialog',
}

@NgModule({
  declarations: [
    SortPickOrderItemsInPickOrderCategoryPipe,
  ],
  imports: [
    HttpClientModule
  ],
  providers: [
    TranslatePipe,
    DatePipe,

    // Guards
    RegisteredDeviceGuard,

    // Pipes
    SortPickOrderItemsInPickOrderCategoryPipe,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'
    },
    {
      provide: LOCALE_ID, useValue: 'nl-NL'
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {subscriptSizing: 'dynamic'}
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {formFieldAppearance: 'fill'}
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: '95vw',
        minWidth: '320px',
        maxHeight: null,
        panelClass: 'app-dialog',
      }
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    registerLocaleData(localeNl);
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
