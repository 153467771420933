import {EventEmitter, Injectable} from "@angular/core";
import {Subscription} from "rxjs";
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  environment = environment;
  private webSocket$: Subscription;
  webSocketAutoRetryPending = false;
  webSocket: WebSocketSubject<{ action: string; data?: any }>;
  webSocketError$: EventEmitter<string> = new EventEmitter<string>();
  webSocketNext$: EventEmitter<string> = new EventEmitter<string>();
  webSocketComplete$: EventEmitter<void> = new EventEmitter<void>();
  received$: EventEmitter<{ action: string[], data: any }> =
    new EventEmitter<{ action: string[]; data: any }>(null);
  webSocketConnectedCount = 0;
  webSocketConnected$: EventEmitter<void> = new EventEmitter<void>();
  webSocketConnected = false;
  webSocketReconnected$: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  connect(apiToken: string, employeeBarCode) {
    this.webSocket$?.unsubscribe();
    this.webSocket = webSocket({
      url: `${environment.ws}?apiToken=${apiToken}&employeeBarCode=${employeeBarCode}&version=${this.environment.version}`
    });
    this.webSocket$ = this.webSocket.subscribe(
      message => {
        this.webSocketAutoRetryPending = false;
        this.handleIncomingMessage(message);
      },
      err => {
        this.handleError(err);
      },
      () => {
        this.webSocketConnected = false;
        this.webSocketComplete$.emit();
      }
    );

    if (!(this.webSocket$ instanceof Subscription)) return;
    this.webSocketConnectedCount++;
    this.webSocketConnected = true;
    if (this.webSocketConnectedCount > 1) {
      this.webSocketReconnected$.emit();
      this.webSocketConnected$.emit();
    } else {
      this.webSocketConnected$.emit();
    }
  }

  private handleIncomingMessage(message: { action: string, data?: any }) {
    this.webSocketNext$.emit();
    this.webSocketError$.emit(null);
    this.received$.emit({
      action: message.action.split('/'),
      data: message.data
    });
  }

  private handleError(event: any) {
    this.webSocketConnected = true;
    if (event instanceof Event) {
      this.webSocketError$.emit(`Unexpected WS-error; ${JSON.stringify(event)}`);
    } else if (event instanceof CloseEvent) {
      this.webSocketError$.emit(`WS close event: ${JSON.stringify(event)}`);
    } else {
      this.webSocketError$.emit(`Onbekende WS-fout: ${JSON.stringify(event)}`);
    }
    this.webSocket?.unsubscribe();
    this.webSocket$?.unsubscribe();
  }

  clear() {
    this.webSocketAutoRetryPending = false;
    this.webSocket?.complete();
    this.webSocket$?.unsubscribe();
  }
}
