export class PackagingType {
  id: number;
  label: string;
  absoluteImgPath: string;
  minWidth: number;
  maxWidth: number;
  minHeight: number;
  maxHeight: number;
  minLength: number;
  maxLength: number;
  minWeight: number;
  maxWeight: number;
}
