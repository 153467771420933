import {Injectable} from '@angular/core';
import {appConfig, SortItemsBy} from "@app/core";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SortItemsByService {
  private _sortItemsBy: SortItemsBy;
  private sortItemsBy: BehaviorSubject<SortItemsBy>;
  sortItemsBy$: Observable<SortItemsBy>;

  constructor() {
    this._sortItemsBy = SortItemsByService.getInitialSortOrderValue();
    this.sortItemsBy = <BehaviorSubject<SortItemsBy>>new BehaviorSubject(Object.assign({}, this._sortItemsBy));
    this.sortItemsBy$ = this.sortItemsBy.asObservable();
  }

  static getInitialSortOrderValue(): SortItemsBy {
    const localStorageVal = localStorage.getItem(appConfig.localStorageKeys.sortItemsBy);
    if (!localStorageVal) return {sortItemsBy: 'default', direction: 'DESC'};
    return JSON.parse(localStorageVal) as SortItemsBy;
  }

  getSortOrderBy() {
    return this._sortItemsBy;
  }

  setSortOrderBy(sortItemsBy: SortItemsBy) {
    if (sortItemsBy.sortItemsBy === this._sortItemsBy.sortItemsBy) {
      if (sortItemsBy.direction === this._sortItemsBy.direction) {
        return;
      }
    }
    this._sortItemsBy = sortItemsBy;
    localStorage.setItem(appConfig.localStorageKeys.sortItemsBy, JSON.stringify(this._sortItemsBy));
    this.sortItemsBy.next(Object.assign({}, this._sortItemsBy));
  }
}
