export class Package {
  id?: string;
  order?: number;
  packageTypeId?: number;
  length: number;
  width: number;
  height: number;
  weight?: number;
  isCompletedByPackaging?: boolean;
  isCompletedAsOutbound?: boolean;
  location?: string;
}
