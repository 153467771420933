import {Pipe, PipeTransform} from '@angular/core';
import {MatDialogConfig} from "@angular/material/dialog";
import {AuthService} from "@app/core/services/auth.service";

@Pipe({
  name: 'dialogAlignTopForCameraScanType'
})
export class DialogAlignTopForCameraScanTypePipe implements PipeTransform {
  constructor(
    private authService: AuthService
  ) {
  }

  transform(options: MatDialogConfig): void {
    if (!options.panelClass) {
      options.maxWidth = '95vw';
      options.minWidth = '320px';
      options.maxHeight = null;
      options.panelClass = 'app-dialog';
    }
    if (this.authService.getRegisteredDevice().scanType !== 'camera') return;
    options.position = {top: 'flex-start'};
  }
}
