import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ApiExpireDialogComponent} from "@app/shared/components/dialogs/api-expire-dialog/api-expire-dialog.component";
import {interval, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import * as moment from "moment";
import {AuthService} from "@app/core/services/auth.service";
import {Router} from "@angular/router";
import {appConfig} from "@app/core";

@Component({
  selector: 'app-api-expire',
  templateUrl: './api-expire.component.html',
  styleUrls: ['./api-expire.component.scss']
})
export class ApiExpireComponent implements OnInit, OnDestroy {
  @Output() apiTokenValidUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  private apiExpireDialogRef: MatDialogRef<ApiExpireDialogComponent>;
  private apiExpireInterval$: Subscription;
  private secondsToWaitBeforeDisplayApiExpireModal = 60;
  private apiTokenValid = false;

  constructor(
    private dialogRef: MatDialog,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.handleApiExpire();
    this.subscribeToApiExpireChecker();
  }

  private handleApiExpire() {
    const registeredDevice = this.authService.getRegisteredDevice();
    if (!registeredDevice) return this.handleLogOut();

    if (!registeredDevice.apiTokenExpire) return this.handleLogOut();

    const secondsRemainingBeforeApiTokenExpires = moment(registeredDevice.apiTokenExpire).diff(moment(), 'seconds');

    if (secondsRemainingBeforeApiTokenExpires < 0) {
      this.apiTokenValid = false;
      return this.handleLogOut();
    } else {
      this.apiTokenValid = true
      this.apiTokenValidUpdate.emit(this.apiTokenValid);
    }

    if (secondsRemainingBeforeApiTokenExpires === 0) {
      return null;
    }

    if (secondsRemainingBeforeApiTokenExpires <= this.secondsToWaitBeforeDisplayApiExpireModal) {
      this.openExpireDialog();
    } else {
      this.closeExpireDialog()
    }
  }

  private subscribeToApiExpireChecker() {
    this.apiExpireInterval$ = interval(1000).pipe(
      map(() => {
        this.handleApiExpire();
      })
    ).subscribe(() => {
    });
  }

  private openExpireDialog() {
    if (this.apiExpireDialogRef) return;
    this.apiExpireDialogRef = this.dialog.open(ApiExpireDialogComponent);
  }

  private closeExpireDialog() {
    if (!this.apiExpireDialogRef) {
      return null;
    }
    this.apiExpireDialogRef.close();
    this.apiExpireDialogRef = null;
  }

  private handleLogOut() {
    this.dialogRef.closeAll();
    this.authService.unSetEmployee();
    this.authService.unSetRegisteredDevice();
    this.router.navigateByUrl('/register-device');
  }

  ngOnDestroy(): void {
    if (this.apiExpireInterval$) {
      this.apiExpireInterval$.unsubscribe()
    }
  }
}
