import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {NetworkDevice} from "@app/core";
import {WebSocketService} from "@app/core/services/web-socket.service";

@Injectable({
  providedIn: 'root'
})
export class NetworkDevicesService {
  private readonly webSocketService$: Subscription;
  networkDevices$: Observable<NetworkDevice[]> = null;
  private _networkDevices: BehaviorSubject<NetworkDevice[]> = null;
  private dataStore: {
    networkDevices: NetworkDevice[];
  };

  constructor(
    private webSocketService: WebSocketService,
  ) {
    this.dataStore = {networkDevices: []};
    this._networkDevices = <BehaviorSubject<NetworkDevice[]>>new BehaviorSubject(null);
    this.networkDevices$ = this._networkDevices.asObservable();
    this.webSocketService$ = this.webSocketService.received$.subscribe(
      (wsMessage: { action: string[], data: any }) => {
        if (!wsMessage) return;
        if (!['networkDevices', 'networkDevice'].includes(wsMessage.action[0])) return;
        this.handleWsIncomingMessage(wsMessage);
      });
  }

  private handleWsIncomingMessage(wsMessage: { action: string[], data: any }) {
    if (wsMessage.action[1] === 'list') {
      return this.setNetworkDevices(wsMessage.data as NetworkDevice[]);
    }
  }

  public setNetworkDevices(networkDevices: NetworkDevice[]) {
    this.dataStore.networkDevices = networkDevices.filter(networkDevice => {
      return networkDevice.isOnline;
    });

    this._networkDevices.next(Object.assign({}, this.dataStore).networkDevices);
  }

  public getAllNetworkDevices(): NetworkDevice[] {
    return this.dataStore.networkDevices;
  }

  public getNetworkDeviceById(networkDeviceId: string): NetworkDevice {
    return this.dataStore.networkDevices.find(networkDevice => {
      return networkDevice.id.toUpperCase() === networkDeviceId.toUpperCase();
    });
  }

  public clear() {
    this.dataStore.networkDevices = null;
    this._networkDevices.next(Object.assign({}, this.dataStore).networkDevices);
    if (this.webSocketService$) {
      this.webSocketService$.unsubscribe();
    }
  }
}
