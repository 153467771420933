import {PickOrderItem} from "@app/core/models/pick-order-item.model";

export class PickOrderCategory {
  _isAllUnorderedCategoriesGrouped?: boolean;
  _unorderedCategoriesGroupedCount?: number;
  _unorderedCategoriesGroupedItemsCount?: number;

  id: string;
  name: string;
  type: 'ordered' | 'unordered';
  activeEmployeeIds: number[];
  pickOrderItems: PickOrderItem[];
  completedItemsCount: number;
  isCompleted: boolean;
  isPickToLight: boolean;
  totalWeight: number;
  allowLocationCodesScan: boolean;
  totalVolume: number;
}
