import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadCrumbsComponent implements OnInit {
  @Input() breadCrumbs: { url?: string, title: string }[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

}
