import {ManageProductsItem} from '@app/core/models/manage-products-item.model';

export class ManageProductsItemStockDetails extends ManageProductsItem {
  length: number;
  width: number;
  height: number;
  imageUrl?: string;
  stock: {
    lastCount?: Date;
    technicalCount?: number;
    availableCount?: number;
    safetyCount?: number;
  };
  incoming: {
    incomingNumber: string;
    magazineNumber: string;
    sendDate: Date;
    amount: number;
  }[];
  plannedCorrections: {
    correctionDate: Date;
    amount: number;
  }[];
}
