export class Employee {
  id: number;
  name: string;
  barCode: string;
  language: 'nl' | 'en' | 'pl';
  employeeExpire: Date;
  isPaused: false;
  pickToLightColor: string;
  company: string;
  wareHouse: string;
  isOnDuty: boolean;
  isFarSighted: boolean;
  logRawScanInfo: boolean;

  // Inbound
  canAccessInboundModule: boolean;

  // PickOrder
  canAccessOrderModule: boolean;
  canForwardFromPickOrderToPackageModule: boolean
  requiresPackageToBeAddedBeforeCanStartPickOrderDetail: boolean

  // Packaging
  canAccessPackagingModule: boolean;

  // Outbound
  canAccessOutboundModule: boolean;

  // StockCheck
  canAccessStockCheckModule: boolean;
  canPreviewExpectedStockCheckAmount: 'never' | 'always' | 'onlyWhenLessThan10Items';

  // ManageProductModule
  canAccessManageProductsModule: boolean;
  canChangeLocations: boolean;
}
