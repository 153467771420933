import {Injectable} from '@angular/core';
import {Employee, RegisteredDevice} from "@app/core";
import {appConfig} from "@app/core/app-config";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {WebSocketService} from "@app/core/services/web-socket.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // RegisteredDevice
  private _registeredDevice: RegisteredDevice;
  private registeredDevice: BehaviorSubject<RegisteredDevice>;
  registeredDevice$: Observable<RegisteredDevice>;

  // Employee
  private _employee: Employee;
  private employee: BehaviorSubject<Employee>;
  employee$: Observable<Employee>;

  // WebSocket
  private webSocketService$: Subscription;

  public apiOffline = false;

  constructor(
    private webSocketService: WebSocketService,
    private router: Router,
  ) {
    // RegisteredDevice
    this._registeredDevice = null;
    this.registeredDevice = <BehaviorSubject<RegisteredDevice>>new BehaviorSubject(null);
    this.registeredDevice$ = this.registeredDevice.asObservable();

    // Employee
    this._employee = null;
    this.employee = <BehaviorSubject<Employee>>new BehaviorSubject(null);
    this.employee$ = this.employee.asObservable();

    // WebSocket
    this.webSocketService$ = this.webSocketService.received$.subscribe(
      (wsMessage: { action: string[], data: any }) => {
        if (!wsMessage) return;
        if (wsMessage.action[0] !== 'auth') return;
        this.handleWsIncomingMessage(wsMessage);
      })
  }

  private handleWsIncomingMessage(wsMessage: { action: string[], data: any }) {
    switch (wsMessage.action[1]) {

      case 'reloadApplication':
        location.reload();
        break;

      case 'Unauthorized':
      case 'unRegisterDevice':
        this.unSetEmployee();
        this.unSetRegisteredDevice();
        this.router.navigateByUrl('/');
        break;

      case 'updateEmployee':
        this.setEmployee(wsMessage.data.employee as Employee, wsMessage.data.redirectToDashboard);
        break;

      case 'unsetEmployee':
        this.unSetEmployee();
        this.router.navigateByUrl('/set-employee');
        break;
    }
  }

  // RegisteredDevice
  private triggerRegisteredDeviceUpdate() {
    this.registeredDevice.next(Object.assign({}, this._registeredDevice));
  }

  setRegisteredDevice(registeredDevice: RegisteredDevice) {
    this._registeredDevice = registeredDevice;
    this.updateRegisteredDevice();
  }

  private updateRegisteredDevice() {
    localStorage.setItem(appConfig.localStorageKeys.registeredDevice, JSON.stringify(this._registeredDevice));
    this.triggerRegisteredDeviceUpdate();
  }

  unSetRegisteredDevice() {
    this._registeredDevice = null;
    localStorage.removeItem(appConfig.localStorageKeys.registeredDevice);
    this.triggerRegisteredDeviceUpdate();
  }

  getRegisteredDevice(): RegisteredDevice {
    return this._registeredDevice;
  }

  setApiTokenExpire(apiTokenExpire) {
    if (!this._registeredDevice) {
      return null;
    }
    this._registeredDevice.apiTokenExpire = apiTokenExpire;
    this.updateRegisteredDevice();
  }

  // Employee
  private triggerEmployeeUpdate() {
    this.employee.next(Object.assign({}, this._employee));
  }

  setEmployee(employee: Employee, redirectToDashboard = false) {
    this._employee = employee;
    this.updateEmployee();
    if (redirectToDashboard) {
      this.router.navigateByUrl('/dashboard');
    }
  }

  private updateEmployee() {
    localStorage.setItem(appConfig.localStorageKeys.employee, JSON.stringify(this._employee));
    this.triggerEmployeeUpdate();
  }

  unSetEmployee() {
    this._employee = null;
    localStorage.removeItem(appConfig.localStorageKeys.employee);
    this.triggerEmployeeUpdate();
  }

  getEmployee(): Employee {
    return this._employee;
  }

  pauseEmployee() {
    if (this._employee.isPaused) return null;
    this.webSocketService.webSocket.next({
      action: 'employee/pause',
      data: {
        employeeId: this._employee.id
      }
    })
  }

  unPauseEmployee() {
    if (!this._employee.isPaused) return null;
    this.webSocketService.webSocket.next({
      action: 'employee/unpause',
      data: {
        employeeId: this._employee.id
      }
    })
  }
}
