import {InboundOrderItem} from "@app/core/models/inbound-order-item.model";
import {Photo} from "@app/core/models/photo.model";

export class InboundOrder {
  id: string;
  name: string;
  deliverByName?: string;
  activeEmployeeId?: number;
  inboundOrderItems?: InboundOrderItem[];
  totalItemsCount?: number;
  completedItemsCount?: number;
  photos?: Photo[];
  deliverDate?: Date;
  vrStatus?: string;
}
