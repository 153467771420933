import { Pipe, PipeTransform } from '@angular/core';
import {PickOrderCategory, PickOrderItem, SortItemsBy} from "@app/core";

@Pipe({
  name: 'sortPickOrderItemsInPickOrderCategory'
})
export class SortPickOrderItemsInPickOrderCategoryPipe implements PipeTransform {

  transform(pickOrderCategory: PickOrderCategory, sortItemsBy: SortItemsBy): PickOrderCategory {
    if (sortItemsBy.sortItemsBy === 'default') {
      pickOrderCategory.pickOrderItems.map(pickOrderItem => {
        pickOrderItem._sortOrder = pickOrderItem.sortOrder;
      })
    } else {
      let customSortedItems: PickOrderItem[];

      if (sortItemsBy.sortItemsBy === 'weight') {
        if(sortItemsBy.direction === 'ASC') {
          customSortedItems = this.getCustomSortedPickOrderItemsByWeightAscending([...pickOrderCategory.pickOrderItems]);
        } else if(sortItemsBy.direction === 'DESC') {
          customSortedItems = this.getCustomSortedPickOrderItemsByWeightDescending([...pickOrderCategory.pickOrderItems]);
        }
      } else if (sortItemsBy.sortItemsBy === 'volume') {
        if(sortItemsBy.direction === 'ASC') {
          customSortedItems = this.getCustomSortedPickOrderItemsByVolumeAscending([...pickOrderCategory.pickOrderItems]);
        } else if(sortItemsBy.direction === 'DESC') {
          customSortedItems = this.getCustomSortedPickOrderItemsByVolumeDescending([...pickOrderCategory.pickOrderItems]);
        }
      } else if (sortItemsBy.sortItemsBy === 'location') {
        if(sortItemsBy.direction === 'ASC') {
          customSortedItems = this.getCustomSortedPickOrderItemsByLocationAscending([...pickOrderCategory.pickOrderItems]);
        } else if(sortItemsBy.direction === 'DESC') {
          customSortedItems = this.getCustomSortedPickOrderItemsByLocationDescending([...pickOrderCategory.pickOrderItems]);
        }
      }
      pickOrderCategory.pickOrderItems = customSortedItems.map((pickOrderItem, i) => {
        pickOrderItem._sortOrder = i;
        return pickOrderItem;
      })
    }

    return pickOrderCategory;
  }

  private getCustomSortedPickOrderItemsByWeightDescending(pickOrderItems: PickOrderItem[]) {
    return pickOrderItems.sort((a, b) => {
      const aWeight = a.description.weightPerItem ? a.description.weightPerItem : 0;
      const bWeight = b.description.weightPerItem ? b.description.weightPerItem : 0;
      return bWeight - aWeight;
    })
  }

  private getCustomSortedPickOrderItemsByWeightAscending(pickOrderItems: PickOrderItem[]) {
    return pickOrderItems.sort((a, b) => {
      const aWeight = a.description.weightPerItem ? a.description.weightPerItem : 0;
      const bWeight = b.description.weightPerItem ? b.description.weightPerItem : 0;
      return aWeight - bWeight;
    })
  }

  private getCustomSortedPickOrderItemsByVolumeDescending(pickOrderItems: PickOrderItem[]) {
    return pickOrderItems.sort((a, b) => {
      const aVolume = a.description.volumePerItem ? a.description.volumePerItem : 0;
      const bVolume = b.description.volumePerItem ? b.description.volumePerItem : 0;
      return bVolume - aVolume;
    })
  }

  private getCustomSortedPickOrderItemsByVolumeAscending(pickOrderItems: PickOrderItem[]) {
    return pickOrderItems.sort((a, b) => {
      const aVolume = a.description.volumePerItem ? a.description.volumePerItem : 0;
      const bVolume = b.description.volumePerItem ? b.description.volumePerItem : 0;
      return aVolume - bVolume;
    })
  }

  private getCustomSortedPickOrderItemsByLocationDescending(pickOrderItems: PickOrderItem[]) {
    return pickOrderItems.sort((a, b) => {
      const aLocation = (a.locationCodes && a.locationCodes[0]) ? a.locationCodes[0] : '';
      const bLocation = (b.locationCodes && b.locationCodes[0]) ? b.locationCodes[0] : '';
      if (aLocation < bLocation) return -1;
      if (aLocation > bLocation) return 1;
      return 0;
    })
  }

  private getCustomSortedPickOrderItemsByLocationAscending(pickOrderItems: PickOrderItem[]) {
    return pickOrderItems.sort((a, b) => {
      const aLocation = (a.locationCodes && a.locationCodes[0]) ? a.locationCodes[0] : '';
      const bLocation = (b.locationCodes && b.locationCodes[0]) ? b.locationCodes[0] : '';
      if (bLocation < aLocation) return -1;
      if (bLocation > aLocation) return 1;
      return 0;
    })
  }

}
