<div class="wrapper">
  <div class="image-wrapper">
    <img alt="Begra logo" src="/assets/img/begra-logo-blue.png"/>
  </div>

  <app-info-pane [animateIn]="true" [text]="'System servers offline'" [size]="'large'"
                 [infoPaneStyle]="'notification'"></app-info-pane>

  <div class="button-wrapper">
    <button mat-raised-button
            color="accent"
            [appButtonIsPending]="isPending"
            (click)="onReconnect()">
      <mat-icon>autorenew</mat-icon>
      Reconnect
    </button>
  </div>
</div>
