import {ManageProductsItemLocation} from "@app/core/models/manage-products-item-locations.model";

export class ManageProductsItem {
  id: string;
  primary: string;
  secondary: string;
  title: string;
  unit: string;
  volumePerItem: number;
  weightPerItem: number;
  locations: ManageProductsItemLocation[];
}
