import {Injectable, OnDestroy} from '@angular/core';

@Injectable({providedIn: 'root'})
export class LogsService implements OnDestroy {

  log: string;

  constructor() {
  }

  addLog(toAdd: string) {
    if (this.log) {
      this.log = `${this.log}<br/>${toAdd}`;
    } else {
      this.log = toAdd;
    }
  }

  clearLog() {
    this.log = undefined;
  }

  ngOnDestroy(): void {
    this.log = undefined;
  }
}
