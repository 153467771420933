import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "./core/services/auth.service";
import {ScanService} from "./core/services/scan.service";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {WebSocketService} from "@app/core/services/web-socket.service";
import {appConfig} from '@app/core/index';
import {LogsDialogComponent} from '@app/dashboard/components/generic/dialogs/logs-dialog/logs-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-manual-scan-input',
  templateUrl: './manual-scan-input.component.html',
  styleUrls: ['./manual-scan-input.component.scss']
})
export class ManualScanInputComponent implements OnInit {
  @Input() readingKeyUpInput: boolean;
  @Input() scannerInputValue: string;
  form: UntypedFormGroup;

  constructor(
    private matDialog: MatDialog,
    private webSocketService: WebSocketService,
    public authService: AuthService,
    public scanService: ScanService,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      scan: new UntypedFormControl(null)
    });
  }

  sendForm() {
    this.scanService.handleManualInput(this.form.get('scan').value);
    this.form.reset();
  }

  onResetClick() {
    this.webSocketService.webSocket.next({
      action: 'resetTestEmployeeData',
      data: null
    });
  }

  openLogs() {
    const dialogConfig = Object.assign({}, appConfig.fullScreenDialogConfig);
    this.matDialog.open(LogsDialogComponent, dialogConfig);
  }
}
